/**
* @core/windmill.splitting
* <br><br>
* ## Windmill Splitting.
*
* - Perform Splitting.js
*
* @module windmill
* @preferred
*/

import splitting from "@core/splitting";

export class WindmillSplitting {  
  /**
  * Plugin installation.
  */
  install(windmill) {
    // run Splitting.js before ready/enter transition
    windmill.on('ready', this._onReady, this);
    windmill.on('enter', this._onEnter, this);
  }

  _onReady() { splitting(); }
  _onEnter() { splitting(); }
}

export default WindmillSplitting;
