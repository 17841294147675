import Splitting from "splitting";

import { $, $$, body } from "@utils/dom";

const addLineOddEven = (el, options, ctx) => {
  const { lines } = ctx;
  lines.forEach((line, line_index) => {
    line.forEach(word => {
      const oddEven = line_index%2 ? 'even' : 'odd';
      word.setAttribute('data-odd-even', oddEven);
    });
  });

  return [];
};

// split by words and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'lineOddEven',
  key: 'lineOddEven',
  depends: ['lines'],
  split: addLineOddEven,
});


export default (el = body) => {
  [ ...$$('[data-splitting]', el) ].forEach(text => {
    const splittingMethod = text.dataset.splitting || "chars";

    Splitting({ target: text, by: splittingMethod }).forEach(obj => {
      // check if [data-splitting-target] attribute exists on element
      let target = obj.el.getAttribute('data-splitting-target');
      if( !target ) return;

      // try to find target element
      target = $(target, el);
      if( !target ) return;

      // loop through each object keys (except "el") and copy key's length to target element in CSS variables
      Object.keys(obj).filter(key => key !== 'el').forEach(key => target.style.setProperty(`--${key}-total`, obj[key].length));
    });
  });
}
