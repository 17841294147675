import "requestidlecallback-polyfill";

import EMITTER from "@core/emitter.js";
import { fetchColors } from "@transitions/TransitionColors";
import { $, $$ } from "@utils/dom";
import { on, off } from "@utils/listener";
import { moduleDelays, inViewport } from "./utils";

const SELECTOR = "[data-site-loader]";
const BASE_DELAY = 1500;

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.logoLastLetter = $(".site-loader__logo path:last-child", this.el);
    this.bg = $(".site-loader__bg", this.el);

    this._animateOutPromise = null;
    this._initPromise = null;

    this._onAnimateInCompleted = this._onAnimateInCompleted.bind(this);
    this._onAnimateOutCompleted = this._onAnimateOutCompleted.bind(this);
  }

  init() {
    moduleDelays(350, 750);
    
    [...$$(`main [data-scroll-section]`)].forEach((el, index) => {
      if (inViewport(el)) {
        // increment base delay to each data-scroll-section in view on init
        el.style.setProperty("--delay", `${BASE_DELAY + 300 * index}ms`);
      } else {
        el.style.setProperty("--delay", `0ms`);
      }
    });

    return new Promise(resolve => {
      this._initPromise = resolve;

      on(this.logoLastLetter, 'transitionend', this._onAnimateInCompleted);
      this.el.classList.add('--animate-in');
    });
  }
  ready() {
    return new Promise(resolve => {
      this._animateOutPromise = resolve;

      on(this.bg, 'transitionend', this._onAnimateOutCompleted);
      this.el.classList.add('--animate-out');
    });
  }
  done() {
    window.requestIdleCallback(fetchColors, { timeout: 2000 });
  }

  _onAnimateInCompleted() {
    off(this.logoLastLetter, 'transitionend', this._onAnimateInCompleted);

    // resolve and destroy promise
    this._initPromise();
    this._initPromise = null;
  }
  _onAnimateOutCompleted() {
    off(this.bg, 'transitionend', this._onAnimateOutCompleted);

    // remove panel
    this.el.parentNode.removeChild(this.el);

    // emit to other modules that site-transition is ready
    EMITTER.emit("SiteTransition.ready");

    // resolve and destroy promise
    this._animateOutPromise();
    this._animateOutPromise = null;
  }
}

export default SiteLoader;
