export default [
  "modules/project-preview",
  "modules/video",
  "modules/svg-path-length",
  "modules/svg-eyes",
  "modules/text-ticker",
  "modules/project-single-header",

  "modules/accordions",
  "modules/clock",
  //"modules/google-map",
  "modules/lottie",
  //"modules/newsletter",
  "modules/pb-row-big-title",
  "modules/pb-row-featured-projects",
  "modules/pb-row-form",
  //"modules/pb-row-hero",
  "modules/pb-row-live-site",
  "modules/pb-row-medias",
  "modules/pb-row-medias-pro",
  "modules/pb-row-mill3-logo",
  "modules/pb-row-movable-text",
  "modules/pb-row-oembed",
  "modules/pb-row-partners",
  "modules/pb-row-projects-browsing",
  "modules/pb-row-projects-grid",
  "modules/pb-row-rive",
  "modules/pb-row-tabs",
  "modules/pb-row-testimonials",
  "modules/pb-row-vision-slider",
  //"modules/pb-row-wrapper-reveal",
  "modules/sharing"
];
